import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { FilterObject } from 'state-domains/domain';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { buildConfigurationActivityGroupsUrlWithFilters } from '../../../network/urls';
import { sendRetrieveRequest } from '../../../utils';

export const loadConfigurationActivityGroups = (filters: FilterObject[]): Observable<any> => {
    const obs = sendRetrieveRequest(buildConfigurationActivityGroupsUrlWithFilters(filters));
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const items = convertToCamel<any>(response.ActivityGroup);
            return observableOf(items);
        }),
    );
};
